import React from "react";
import { callLink, whatsappLink } from "../data/contact";
export const CTA = () => {
  const buttonStyle = {
    marginLeft: "5px",
    marginRight: "5px",
    padding: "14px 24px",
  };
  return (
    <>
      <a
        href={callLink}
        className="btn btn-custom btn-lg page-scroll"
        style={buttonStyle}
      >
        Call Now
      </a>
      <a
        href={whatsappLink}
        className="btn btn-custom btn-lg page-scroll"
        style={buttonStyle}
      >
        Whatsapp Us
      </a>
    </>
  );
};
